.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.banner{
  min-height: 50vh;
  background-color: #282c34;
  color: #fff;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.banner .title{
  font-size: 2.4em;
  line-height: 1.2em;
  padding-bottom: 20px;
}

.banner .desc{
  font-size: 1.5em;
  line-height: 1.2em;
  padding-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  display: flex;
  color: white;
  padding: 15px;
}

.App-header a, .bttn{
  display: block;
  padding: 5px 15px;
  background: #36abcc;
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  border-radius: 5px;
}

.App-link, a {
  color: #61dafb;
}

.signupWrap, .loginWrap{
  max-width: 450px;
  margin: 20vh auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #eee;
  min-height: 50vh;
}

.signupWrap label, .loginWrap label{
  min-width: 100px;
  text-align: left;
  display: inline-block;
}

.inputWrap{
  padding-bottom: 10px;
}

.product{
  display: flex;
  margin: 20vh auto;
  max-width: 50vw;
  min-height: 50vh;
  background-color: #36abcc;
  color: #fff;
  font-size: 1.5em;
  align-items: center;
  justify-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
